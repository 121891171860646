// -- main variables
$main_font_family: var(--font_main_family);
$main_font_size: 16px;
$main_font_weight: 400;
$main_line_height: 1.6;

// @todo - pozbyć się zmiennej $colors na rzecz zmiennych css lub bootstrap
$colors: (
        "white": #ffffff,
        "red": #F61237,
        "grey": #868789,
        "light-grey": #dddddd,
        "lighten-grey": #f8f8f8,
        //"dark-grey": #231f20,
        "black": #000000,

        "lighter-grey": #a6a6a7,
        "gold": #cba346,
        "dark-grey": #353434,
        "green": #327d45
);

$main_text_color: var(--color_font_page);
$main_bg: var(--color_bg_page);
$main_color: var(--color_bg_button);
$main_border_color: map-get($colors, 'light-grey');
$main_border: 1px solid $main_border_color;
$main_border_radius: 5px;

$header_font_family: var(--font_header_family);
$headline_bg: var(--color_bg_title);
$subheader_font_family: var(--font_subheader_family);

$menu_font_family: var(--font_menu_family);
$menu_font_weight: var(--menu_font_weight);
$menu_font_transform: var(--menu_font_transform);
$menu_font_style: var(--menu_font_style);
$menu_button_font_transform: var(--menu_button_font_transform);

$link_color: $main_color;
$link_text_decoration: none;
$link_hover_color: $main_color;
$link_hover_text_decoration: underline;
$error_color: map-get($colors,"red");

$main_font_size_map: (
  min: 14px,
  max: 16px
);
